<template>
  <div class="container">
    <div class="m-0 col-8 p-2 filter-box-wrapper bg-light rounded">
      <events-date-picker
        v-model="dateRangeFilter"
        @range-updated="updateDateRangeFilter"
        input-class="form-control width-wide"
      />
      <input
        type="text"
        class="form-control bg-light"
        placeholder="Фильтр"
        ref="filter_box"
        v-model="filterQuery"
        @change="updateFilterQuery"
      />
    </div>

    <div class="pt-1">
      <items-selector
        v-model="ownersFilter"
        :items="availableOwners"
        button-content="Чьи"
        v-if="availableOwners.length > 1 || ownersFilter.length > 0"
        @items-updated="updateOwnersFilter"
      />
    </div>

    <div class="py-3">
      <spent-tree :data="summary" />
    </div>

    <div class="col-8 pt-0 px-0 pb-5 events-list-wrapper">
      <div class="events-list">
        <event-list-item
          v-for="event in eventsPage"
          :key="event.id"
          :event="event"
          :filter-query="filterQuery"
          :selected-events="bulkModeEventsSelected"
          @event-selected="eventSelected"
          @event-deselected="eventDeselected"
          @category-selected-successfully="updateSummary"
        />
        <p
          v-if="!eventsLoading && eventsPage.length === 0"
          class="text-muted text-center"
        >
          Ничего нет 🤷‍♂️
        </p>
      </div>

      <div
        v-if="hasMoreEvents"
        class="d-flex justify-content-center bg-light mt-3"
      >
        <button
          type="button"
          class="btn btn-link py-2"
          @click="$store.dispatch('events/fetchNext')"
        >
          Ещё..
        </button>
      </div>
    </div>

    <div
      class="d-flex flex-row fixed-bottom shadow border-top bg-white px-5 py-2"
      :class="{ 'imp-hide': !bulkMode }"
    >
      <div style="padding-top: 2px">
        Выбрано событий: {{ Object.keys(bulkModeEventsSelected).length }}
      </div>
      <span class="text-muted px-2">•</span>
      <event-category-selector
        :event-owner-id="currentUser.id"
        :events="Object.values(bulkModeEventsSelected)"
        :selected-category="null"
        @category-selected="deselectAllEvents"
        @category-selected-successfully="updateSummary"
        :noCategoryText="'Установить категорию'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpentTree from '@/components/views/events/SpentTree'
import EventListItem from '@/components/views/events/EventListItem'
import EventCategorySelector from '@/components/views/events/EventCategorySelector.vue'
import EventsDatePicker from '@/components/common/EventsDatePicker'
import ItemsSelector from '@/components/common/ItemsSelector'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'

export default {
  data() {
    return {
      filterQuery: this.$route.query.filter || '',
      ownersFilter: this.parseQueryArray(this.$route.query.owners || ''),
      dateRangeFilter: this.parseQueryArray(
        this.$route.query.date_range || '',
        ':'
      ),
      bulkModeEventsSelected: {},
      isUpdating: false
    }
  },
  computed: {
    ...mapGetters({
      eventsPage: 'events/getPage',
      hasMoreEvents: 'events/hasMoreEvents',
      summary: 'events/getSummary',
      eventsLoading: 'events/getEventsLoading',
      currentUser: 'auth/getCurrentUser'
    }),
    bulkMode() {
      return Object.keys(this.bulkModeEventsSelected).length > 0
    },
    availableOwners() {
      const result = [
        {
          id: this.currentUser.id,
          name: this.currentUser.first_name
        }
      ]
      this.$store.getters['share/getAvailableOwners'].forEach((owner) => {
        result.push({ id: owner.id, name: owner.first_name })
      })
      return result
    }
  },
  methods: {
    async updateEvents() {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true

      try {
        await this.updateDateRangeFilter()
        await this.updateOwnersFilter()

        const eventsPromise = this.$store.dispatch('events/fetch', {
          filter: this.filterQuery,
          owners: this.ownersFilter,
          dateRange: this.dateRangeFilter
        })
        const summaryPromise = this.$store.dispatch('events/fetchSummary', {
          filter: this.filterQuery,
          owners: this.ownersFilter,
          dateRange: this.dateRangeFilter
        })
        await Promise.all([eventsPromise, summaryPromise])
      } finally {
        this.isUpdating = false
      }
    },
    updateFilterQuery() {
      if (this.$route.query.filter !== this.filterQuery) {
        this.$router.push({
          query: { ...this.$route.query, filter: this.filterQuery }
        })
      }
    },
    updateOwnersFilter() {
      let value = this.ownersFilter
      if (value.length === 0 && this.availableOwners.length > 1) {
        value = [this.currentUser.id]
      }
      const joined = value.join(',')
      if (this.$route.query.owners !== joined) {
        this.$router.push({ query: { ...this.$route.query, owners: joined } })
      }
    },
    updateDateRangeFilter() {
      let value = this.dateRangeFilter
      if (value.length === 0) {
        const d = dayjs().locale('ru')
        const start = d.startOf('month')
        const end = d
        value = [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')]
      }
      const joined = value.join(':')
      if (this.$route.query.date_range !== joined) {
        this.$router.push({
          query: { ...this.$route.query, date_range: joined }
        })
      }
    },
    updateSummary() {
      this.$store.dispatch('events/fetchSummary', {
        filter: this.filterQuery,
        owners: this.ownersFilter
      })
    },
    eventSelected(event) {
      this.$set(this.bulkModeEventsSelected, event.id, event)
    },
    eventDeselected(event) {
      this.$delete(this.bulkModeEventsSelected, event.id)
    },
    deselectAllEvents() {
      for (const eventId in this.bulkModeEventsSelected) {
        this.$delete(this.bulkModeEventsSelected, eventId)
      }
    },
    parseQueryArray(queryString, sep) {
      sep = sep || ','
      return queryString ? queryString.split(sep) : []
    }
  },
  watch: {
    '$route.query.owners'(newUrlOwners) {
      this.ownersFilter = this.parseQueryArray(newUrlOwners || '')
    },
    '$route.query.filter'(newUrlFilter) {
      this.filterQuery = newUrlFilter || ''
    },
    '$route.query.date_range'(newUrlDateRange) {
      this.dateRangeFilter = this.parseQueryArray(newUrlDateRange || '', ':')
    },
    '$route.query'() {
      this.updateEvents()
    }
  },
  async created() {
    await this.$store.dispatch('share/fetchAvailableOwners')
    await this.updateEvents()
  },
  components: {
    SpentTree,
    EventListItem,
    EventCategorySelector,
    ItemsSelector,
    EventsDatePicker
  }
}
</script>

<style lang="scss" scoped>
.imp-hide {
  display: none !important;
}

.datepicker-input {
  width: 218px;
}

.filter-box-wrapper {
  display: flex;

  input {
    border: none;
    box-shadow: none;

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
}

@media (max-width: 991.98px) {
  .filter-box-wrapper {
    display: block;
  }

  .events-list-wrapper {
    max-width: 100% !important;
    width: 100% !important;
  }
  .filter-box-wrapper {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.events-list > div:last-child {
  border-bottom: 1px solid #dee2e6 !important;
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.events-list > div:first-child {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
</style>
