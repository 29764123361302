<template>
  <date-picker
    v-model="range"
    :lang="lang"
    range
    range-separator=" - "
    value-type="format"
    format="YYYY-MM-DD"
    :clearable="false"
    :disabled-date="disableFutureDates"
    :input-class="'m-inp' + ' ' + inputClass"
    :confirm="true"
    confirm-text="Готово"
    @change="onChange"
  >
    <template #icon-calendar>
      <!-- не хочу иконку -->
      <span></span>
    </template>
    <template v-slot:sidebar="{ emit }">
      <div>
        <button class="mx-btn mx-btn-text" @click="currentWeekRange(emit)">
          Неделя
        </button>
        <button class="mx-btn mx-btn-text" @click="currentMonthRange(emit)">
          {{ currentMonthName }}
        </button>
        <button class="mx-btn mx-btn-text" @click="currentYearRange(emit)">
          {{ currentYear }}
        </button>
      </div>
    </template>
  </date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

export default {
  name: 'EventsDatePicker',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    inputClass: {
      type: String
    }
  },
  data() {
    return {
      range: this.value,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь'
          ],
          monthsShort: [
            'Янв',
            'Фев',
            'Мар',
            'Апр',
            'Май',
            'Июн',
            'Июл',
            'Авг',
            'Сен',
            'Окт',
            'Ноя',
            'Дек'
          ],
          weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
        }
      }
    }
  },
  computed: {
    currentMonthName() {
      return this.lang.formatLocale.months[dayjs().month()]
    },
    currentYear() {
      return dayjs().year()
    }
  },
  methods: {
    currentMonthRange(emit) {
      const d = dayjs().locale('ru')
      const start = d.startOf('month')
      emit([start.toDate(), d.toDate()])
    },
    currentWeekRange(emit) {
      const d = dayjs().locale('ru')
      const start = d.startOf('week')
      emit([start.toDate(), d.toDate()])
    },
    currentYearRange(emit) {
      const d = dayjs().locale('ru')
      const start = d.startOf('year')
      emit([start.toDate(), d.toDate()])
    },
    disableFutureDates(date) {
      const today = new Date()
      return date > today
    },
    onChange(newValue) {
      this.range = newValue
      this.$emit('input', this.range)
      this.$emit('range-updated')
    }
  },
  watch: {
    value(newValue) {
      this.range = newValue
    }
  },
  components: {
    DatePicker
  }
}
</script>

<style lang="scss">
.width-wide {
  width: 218px;
}
</style>
