import ApiService from '@/services/api.service'

const namespaced = true

const state = {
  availableOwners: [],
  availableOwnersLoading: false
}

const getters = {
  getAvailableOwners (state) {
    return state.availableOwners
  },
  getAvailableOwnersLoading (state) {
    return state.availableOwnersLoading
  }
}

const actions = {
  fetchAvailableOwners (context) {
    context.commit('setAvailableOwnersLoading', true)
    return ApiService.get('/share/available-owners')
      .then(response => {
        context.commit('setAvailableOwners', response.data.owners)
      })
      .finally(() => {
        context.commit('setAvailableOwnersLoading', false)
      })
  }
}

const mutations = {
  setAvailableOwners (state, availableOwners) {
    state.availableOwners = availableOwners
  },
  setAvailableOwnersLoading (state, isLoading) {
    state.availableOwnersLoading = isLoading
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
